<template>
  <div>
    <div class="workCard">
      <div class="workCardLeft">
        <TitleMore
          :title="['工作动态']"
          @handleMore="gzhandleMore"
          style="width: 100%"
        ></TitleMore>
        <div class="homeThree">
          <el-carousel style="width: 48%">
            <el-carousel-item v-for="item in ListDataBanner" :key="item.id">
              <img
                style="width: 100%; height: 100%"
                :src="item.smallImage"
                @click="$goto('/detailShow', { id: item.id })"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
          <div class="homeThreeRight">
            <div
              class="itemText"
              style="margin-bottom: 10px"
              v-for="(item, index) in ListData"
              :key="index"
              @click="$goto('/detailShow', { id: item.id })"
            >
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">{{ item.title }}</span>
              </p>
              <p class="itemTextTime">{{ item.publishDate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="workCardRight">
        <div>
          <TitleMore
            :title="['救护员培训']"
            moreTitle="查看详情"
            :isMore="false"
            style="margin-top: 40px; width: 100%"
          ></TitleMore>
          <div class="rescueTrain">
            <div class="rescueTrainContent">
              <p
                style="
                  font-size: 14px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 24px;
                  text-align: center;
                "
              >
                截至{{ trainNumData.publishDate }}四川省救护员培训
              </p>
              <p style="font-size: 30px; font-weight: bold; color: #d72829">
                {{ trainNumData.student_count }}
                <span style="font-size: 14px; font-weight: bold; color: #ffffff"
                  >人</span
                >
              </p>
            </div>
            <div class="rightImg">
              <img
                style="width: 130px; height: 130px"
                :src="trainNumData.student_QRcode"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <TitleMore
            :title="['普及培训']"
            moreTitle="查看详情"
            :isMore="false"
            style="margin-top: 40px; width: 100%"
          ></TitleMore>
          <div class="rescueTrains">
            <div class="rescueTrainContent">
              <p
                style="
                  font-size: 14px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 24px;
                  text-align: center;
                "
              >
                截至{{ trainNumData.publishDate }}
                四川省普及培训
              </p>
              <p style="font-size: 30px; font-weight: bold; color: #d72829">
                {{ trainNumData.universal_train_count }}
                <span style="font-size: 14px; font-weight: bold; color: #ffffff"
                  >人</span
                >
              </p>
            </div>
            <div class="rightImg">
              <img
                style="width: 130px; height: 130px"
                :src="trainNumData.universal_train_QRcode"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <TitleMore
      :title="['应急救护课程']"
      @handleMore="$goto('/class')"
    ></TitleMore>
    <div class="baseCard classCardMain">
      <el-row>
        <el-col
          :span="8"
          v-for="(item, index) in classListData"
          :key="index"
          class="unit"
        >
          <div
            class="classCard"
            @click="$goto('/classDetail', { id: item.id })"
          >
            <img
              v-show="item.kc_state == '报名中'"
              class="statusImg"
              src="../../assets/img/applying.png"
              alt=""
            />
            <img
              v-show="item.kc_state == '培训中'"
              class="statusImg"
              src="../../assets/img/training.png"
              alt=""
            />
            <img
              v-show="item.kc_state == '已结束'"
              class="statusImg"
              src="../../assets/img/end.png"
              alt=""
            />
            <img
              style="width: 100%; height: 196px"
              :src="item.smallImage"
              alt=""
            />
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
                text-align: center;
								height: 60px;
								display: -webkit-box;
								line-clamp: 2;
								overflow: hidden;
              "
            >
              {{ item.title }}
            </p>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">已报名人数</span>
              </p>
              <p class="rescueText">{{ item.has_enroll_count }}</p>
            </div>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">预计培训时间</span>
              </p>
              <p class="rescueText">{{ item.estimate_train_date }}</p>
            </div>
            <div class="itemText">
              <p>
                <img src="../../assets/img/list-little.png" alt="" />
                <span class="itemTextContent">预计培训人数</span>
              </p>
              <p class="rescueText">{{ item.estimate_train_count}}人</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      ListDataBanner: [],
      ListData: [],
      classListData: [],
      trainNumData: {},
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.getList();
    this.getClassList();
    this.getTrainNum();
  },

  methods: {
    gzhandleMore() {
      this.$router.push({
        path: "/imgDetail",
        query: { name: "工作动态", type: "yjjhjj_gzdt" },
      });
    },
    getList() {
      apiUrls.selfDynamicList({ node: "yjjhjj_gzdt", size: 12 }).then((res) => {
        // this.ListDataBanner = res.results.data.slice(0, 3);
        // this.ListData = res.results.data;
        let _this = this;
        res.results.data.forEach((item, ind) => {
          if (_this.ListDataBanner.length < 3 && item.smallImage) {
            _this.ListDataBanner.push(item);
          } else if(_this.ListData.length<8) {
            _this.ListData.push(item);
          }
        });
      });
    },
    getClassList() {
      apiUrls.selfDynamicList({ node: "yjjhkclb", size: 6 }).then((res) => {
        this.classListData = res.results.data;
      });
    },
    getTrainNum() {
      apiUrls.trainNum({ node: "yjjhjj_jhypx" }).then((res) => {
        this.trainNumData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.workCard {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
  .workCardLeft {
    display: flex;
    flex-direction: column;
    width: calc(70% - 40px);
    .homeThree {
      height: 390px;
      display: flex;
      background-color: #ffffff;
      box-shadow: 0px 0px 5px 5px #e9e9e9;
      padding: 20px;
      /deep/ .el-carousel__container {
        height: 100%;
      }
      .homeThreeRight {
        width: 48%;
        margin-left: 4%;
        .itemText {
          padding: 10px 0px;
        }
        .htrContent {
          font-size: 14px;
          color: #958d8d;
          border-bottom: 1px dotted #dcdcdc;
          padding: 10px 0px;
          line-height: 30px;
        }
      }
    }
  }
  .workCardRight {
    display: flex;
    flex-direction: column;
    width: calc(30% + 40);
    margin-top: -30px;
    .rescueTrain,
    .rescueTrains {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 185px;
      width: calc(100% + 20px);
      background: url(../../assets/images/jhypx.png) no-repeat;
      background-size: 100% 100%;
      .rescueTrainContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
      }
      .rightImg {
        width: 40%;
      }
    }
    .rescueTrains {
      background: url(../../assets/images/pjpx.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.itemTextContent {
  &:hover {
    color: #d1292e;
  }
}
.classCardMain {
  display: flex;
  flex-direction: column;

  .classCardContent {
    margin-bottom: 20px;
  }
  .unit{
    padding: 12px;
  }
  .classCard {
    position: relative;
    width: 320px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 5px #e9e9e9;
    padding: 20px;
    margin: 0 auto;
    cursor: pointer;
    .statusImg {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .itemText {
      padding: 10px 0px;
    }
  }
}
</style> 